.image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container:hover .options {
  display: block;
}
.options {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px;
}
