.topbar {
  height: 70px;
  padding: 20px;

  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: start;
  align-items: center;

  border-bottom: solid 2px #c9c8c8;

  --toolbar-color: #333;
  overflow-x: auto;
  overflow-y: hidden;
}

.topbar .MuiOutlinedInput-root {
  padding: 0;
}
.topbar .MuiButton-outlined,
.topbar .MuiButtonBase-root,
.topbar .MuiOutlinedInput-notchedOutline {
  border-color: var(--toolbar-color) !important;
  color: var(--toolbar-color) !important;
  border: 1px solid var(--toolbar-color) !important;
  border-radius: 2px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px !important;
}

.topbar .MuiFormLabel-root,
.topbar .MuiInputBase-input {
  color: var(--toolbar-color) !important;
}
