.edit {
  display: none;
}
.design:hover .edit {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.design {
  position: relative;
}

.design.blank:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.design.blank:active {
  background-color: #e0e0e0;
  box-shadow: none;
}
