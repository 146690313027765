.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  display: block;
}

/* masonry */
.gallery-grid {
  display: flex;
  justify-content: space-between;
}

.gallery-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.gallery-item {
  margin-bottom: 1em;
}

/* alt text upon hover */
.gallery-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 1em;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
}

.alt-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(27, 58, 150);
  padding: 1.7em;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  cursor: pointer;
}

.gallery-item:hover .alt-text {
  opacity: 1;
}
