* {
  box-sizing: border-box;
  outline: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root {
  height: 100%;
}

p {
  margin: 0;
  padding: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.SVGContainer {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}
.SVGContainer svg {
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex-grow: 1;
  user-select: none;

  overflow: hidden;
}
.ToolbarColumns {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 5px;
}
.toolbar-item {
  width: 38px;
}

.shapesmenu-icon {
  width: 0.9rem !important;
  height: 0.9rem !important;
  margin-right: 5px;
  opacity: 0.8;
}

.Layers {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: stretch;

  position: relative; /* for the playhead */
  font-size: 0.7rem;
}
.Layers button {
  padding: 2px;
}
.Layers svg {
  height: 18px;
}

.open-layer {
  background-color: #f1f1f1;
}

svg text {
  cursor: default;
}

.layer-icon {
  opacity: 0.2;
  width: 20px !important;
  height: 20px !important;
}

.layer-icon.selected {
  opacity: 0.9;
}
.layer-icon.pointer {
  transform: rotate(-45deg);
}
.layer-icon:hover {
  opacity: 0.7;
}

li {
  list-style-type: none;
}
ul {
  padding: 0;
  margin: 0;
}

.sketch-picker {
  background: transparent !important;
  border-radius: 0 !important;
  border: unset !important;
  box-shadow: none !important;
  margin: 0px 8px;
}

.load-textarea-parent {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
}
.load-textarea {
  height: 60vh;
  width: 60vh;
}
.gif-container {
  width: 300px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.BottomBar a {
  text-decoration: none;
}
.BottomBar a:hover {
  text-decoration: underline;
}

.menu-title:hover,
.menu-title.active {
  cursor: default;
}
.subtitle {
  font-size: 0.8rem;
}

.zoomable-svg-parent {
  overflow: hidden;

  border-radius: 5px;
}
.zoomable-svg {
  transition: transform 0.3s ease; /* Smooth transition */
  width: 100%;
  height: 100%;
}
svg.zoomable-svg:hover {
  transform: scale(1.1); /* Adjust the scale factor as needed */
}

/************ for phones ***************/
html,
body {
  /* disable pull to refresh */
  overscroll-behavior: none;
}

/************* to disable scrollbar **********/

/* Hide scrollbar for Chrome, Safari and Opera */

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.leftpanel-button {
  border-radius: 0 !important;
  background-color: #383838 !important;
}
.selected.leftpanel-button {
  background-color: #191919 !important;
}
.leftpanel-details {
  overflow-y: scroll;
}
.leftpanel-details input,
.leftpanel-details button {
  /* color: white; */
}

.pages-svg-container svg {
  width: 100% !important;
  height: 100% !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.lazy-image {
  position: relative;
}
.price-wrapper .price {
  position: absolute;
  top: -7px;
  right: 10px;
  border: solid 1px #2b2b2b;
  background-color: #ffc42a;
  /* color: white; */
  font-size: 0.9rem;
  padding: 3px 8px;
  border-radius: 5px;
}
.add-btn-wrapper {
  border: 1px solid transparent;
  box-shadow: 0 0 3px 0 transparent;
  filter: saturate(0.8);
  margin: 3px;
}
.add-btn-wrapper:hover {
  border-color: #c0c0c0;
  box-shadow: 0 0 2px 0 #c3c3c3;
  filter: saturate(1);
  cursor: pointer;
}
.add-btn-wrapper:hover .add-btn {
  display: flex !important;
}
.add-btn-wrapper:hover img {
  filter: blur(2px);
}

.design-title {
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 5px;
  outline: none !important;
}
.design-title:hover {
  border: 1px solid #c0c0c0;
}

.hide-scrollbar {
  overflow: scroll; /* Enable scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer/Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and newer Edge */
}

p:hover .p-edit {
  display: inline;
}

.rotate-btn {
  cursor: pointer;
  background-color: #c6c6c6 !important;
}

.rotate-btn:hover {
  background-color: #ffc42a !important;
  box-shadow: 0 0 3px 0 #ffc42a;
}

.handle {
  fill: white;
}
.handle.selected {
  /* fill: red !important; */
}

.handle:hover {
  fill: #ffc42a !important;
}

@keyframes shimmer {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #ccc;
  }
}

.see-all {
  color: #555;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
}

.sidebar-design-list-item:hover {
  background-color: #fbecfc !important;
  cursor: pointer;
}

.image-delete-btn-wrapper {
  position: relative;
}
.image-delete-btn-wrapper:hover .image-delete-btn {
  display: flex !important;
}

.text-foreign-object {
  overflow: visible;
}

.commit-info {
  opacity: 0.1;
}
.commit-info:hover {
  opacity: 1;
}
.navbar-btn {
  background: rgb(229 229 229) !important;
  border: 1px solid white !important;
  color: #333 !important;
  border-radius: 5px;
  text-transform: none !important;
  /* width: 100px; */
}
.svg-option-container {
  cursor: pointer;
  border: 1px solid transparent;
}
.svg-option-container:hover {
  border-color: #c0c0c0;
}

.nopadding .MuiSelect-select {
  padding: 0 !important;
}
.nopadding .MuiSvgIcon-root {
  display: none;
}

.close-wrapper:hover .close-icon {
  display: block !important;
}
.palette-holder:hover {
  background-color: #484747;
}

.palette-holder:hover .palette-btn {
  display: flex !important;
}

.edit-color-icon-wrapper:hover .edit-color-icon {
  display: flex !important;
}

@media (max-width: 1000px) {
  .min-width-1000 {
    display: none;
  }
}
@media (min-width: 1000px) {
  .max-width-1000 {
    display: none;
  }
}

/* fake scrollbar */
.fake-scrollbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: #f0f0f0;
}

.fake-scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: #888;
  cursor: pointer;
}
